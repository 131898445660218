/* style text for map icons */
.price-icon {
  border-radius: 50%;
  box-shadow: none;
  border: none;
  background: transparent;
  width: 32px;
  height: 32px;
}
/* move zoom control out from under header */
.leaflet-control-zoom {
  position: fixed;
  top: 4rem;
  right: 1rem;
}

/* Scroll Bar CSS */

.leaflet-popup-scrolled::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.leaflet-popup-scrolled::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.leaflet-popup-scrolled::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.popup {
  padding-top: 100px;
}
